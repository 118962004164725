/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-content #bodyView .tag-selector {
  width: 100%;
  margin-top: 10px;
}
.altai-theme-content #bodyView .tag-selector .tag-container {
  position: relative;
  display: inline-block;
}
.altai-theme-content #bodyView .tag-selector .tag-container .dropdown {
  position: absolute;
  background-color: #F9F9F9;
  top: 30px;
  left: 0;
  width: 300px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  min-height: 1px;
  overflow: hidden;
  z-index: 70;
  transition: min-height 0.05s ease-in-out;
}
.altai-theme-content #bodyView .tag-selector .tag-container .dropdown.expanded {
  display: block;
  min-height: 20px;
}
.altai-theme-content #bodyView .tag-selector .tag-container .subtag {
  display: block;
  border-left: 5px solid #d9d9d9;
  padding: 10px;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-transition: border-left 0.2s ease-in-out, border 0.3s ease-in-out;
  -o-transition: border-left 0.2s ease-in-out, border 0.3s ease-in-out;
  -webkit-transition: border-left 0.2s ease-in-out, border 0.3s ease-in-out;
  transition: border-left 0.2s ease-in-out, border 0.3s ease-in-out;
}
.altai-theme-content #bodyView .tag-selector .tag-container .subtag .title {
  font-size: inherit;
  line-height: inherit;
}
.altai-theme-content #bodyView .tag-selector .tag-container .subtag .check {
  visibility: hidden;
  width: 20px;
}
.altai-theme-content #bodyView .tag-selector .tag-container .subtag:hover {
  border-left: 5px solid #0068ff;
}
.altai-theme-content #bodyView .tag-selector .tag-container .subtag.active {
  border-left: 5px solid #0068ff;
}
.altai-theme-content #bodyView .tag-selector .tag-container .subtag.active .check {
  visibility: visible;
  float: left;
  display: inline;
}
.altai-theme-content #bodyView .tag-selector .tag {
  background-color: #e4e4e4;
  padding: 4px 20px 4px 20px;
  padding-left: 30px;
  border-radius: 5px;
  margin-right: 7px;
  color: black;
  display: inline-block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 7px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.altai-theme-content #bodyView .tag-selector .tag .check {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #d9d9d9;
  width: 20px;
  color: #d9d9d9;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-transition: background-color 100ms ease, color 100ms ease;
  -o-transition: background-color 100ms ease, color 100ms ease;
  -webkit-transition: background-color 100ms ease, color 100ms ease;
  transition: background-color 100ms ease, color 100ms ease;
}
.altai-theme-content #bodyView .tag-selector .tag .chevron {
  display: none;
}
.altai-theme-content #bodyView .tag-selector .tag-title-container {
  display: flex;
}
.altai-theme-content #bodyView .tag-selector .tag-title-container .title {
  padding-left: 10px;
  font-size: 100%;
  line-height: inherit;
  font-family: inherit;
  text-decoration: none;
}
.altai-theme-content #bodyView .tag-selector .tag.parent {
  padding: 4px 5px 4px 20px;
}
.altai-theme-content #bodyView .tag-selector .tag.parent .chevron {
  right: 4px;
  top: 5px;
  display: block;
  transition: all 0.2s linear;
  margin-left: 20px;
}
.altai-theme-content #bodyView .tag-selector .tag.parent .tag-title-container .title {
  font-size: 100%;
  line-height: inherit;
  font-family: inherit;
  text-decoration: none;
}
.altai-theme-content #bodyView .tag-selector .tag.active .check {
  background-color: #0068ff;
  color: white;
}
.altai-theme-content #bodyView .tag-selector .tag.parent:hover .check {
  background-color: #d9d9d9;
  color: #d9d9d9;
}
.altai-theme-content #bodyView .tag-selector .tag.parent:hover .chevron {
  color: #0068ff;
}
.altai-theme-content #bodyView .tag-selector .tag.parent.active:hover .check {
  background-color: #0068ff;
  color: white;
}
.altai-theme-content #bodyView .tag-selector .hasChildren {
  background-color: #0068ff !important;
}
.altai-theme-content #bodyView .tag-selector .mdi-checkbox-blank-circle {
  font-size: 8px;
  color: white;
}
.altai-theme-content #bodyView .tag-selector .tag.parent .check:hover {
  background-color: #0068ff;
  color: #0068ff;
}
.altai-theme-content #bodyView .tag-selector .tag.active.parent .check:hover {
  color: white;
}
.altai-theme-content #bodyView .tag-selector .tag:hover .check {
  background-color: #0068ff;
  color: #0068ff;
}
.altai-theme-content #bodyView .tag-selector .tag.active:hover .check {
  color: white;
}
.altai-theme-content #bodyView .tag-selector .tag.disabled {
  color: #c1c1c1;
  cursor: default;
}
.altai-theme-content #bodyView .tag-selector .tag.disabled:hover .check {
  background-color: #d9d9d9;
  color: #d9d9d9;
}
.altai-theme-content #bodyView .tag-selector .tag-select-all {
  background-color: #f1f1f1;
}
.altai-theme-content #bodyView .tag-selector .tag-select-all .check {
  background-color: #d9d9d9;
  color: #d9d9d9;
}
.altai-theme-content #bodyView .tag-selector .chevron.rotated {
  transform: rotate(180deg);
}
.altai-theme-content md-checkbox.md-checked .md-ink-ripple {
  color: #0068ff;
}
